<template>
  <div id="publishing">
    <div uk-grid>
      <h1 class="uk-text-left uk-width-expand">{{ $t('Sidebar.Publishing') }}</h1>
      <div>
        <button v-if="isAnyAdmin" class="uk-button uk-button-primary uk-float-right new-folder-btn uk-width-auto" href="#new-publishing-folder-modal" uk-toggle>
          <span class="uk-margin-small-right" uk-icon="plus-circle"></span> {{ $t('Pages.Publishing.NewFolder') }}
        </button>
        <div class="uk-float-right uk-margin-remove-bottom uk-margin-right">
          <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
              <span class="uk-search-icon" uk-search-icon></span>
              <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
              <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
          </form>
      </div>
      </div>
    </div>
    <hr />
    <PublishingDashboard :properties-data="properties" uk-scrollspy="target: > div; cls: uk-animation-slide-top-medium; delay: 50; offset-top: 500;"/>
    <hr />
    <div class="uk-text-center" v-if="!fetching && ( propertyNodes && propertyNodes.length == 0) && !searchKey">
      <span class="uk-text-meta">{{ $t('Pages.Publishing.NoData') }}</span>
      <a class="uk-text-primary" href="#new-publishing-folder-modal" uk-toggle>{{ $t('Pages.Publishing.NewFolder') }}</a>
    </div>

    <div v-if="fetching && !( properties.propertiesConnection && properties.propertiesConnection.nodes)" uk-spinner />
    <vue-good-table
      styleClass="vgt-table uk-table uk-table-small uk-table-striped uk-table-hover uk-table-responsive uk-margin-remove-bottom"
      :columns="tableHeaders"
      :sort-options="{
      enabled: true,
      initialSortBy: {field: 'name', type: 'asc'}
      }"
      v-if="( properties.propertiesConnection && properties.propertiesConnection.nodes && properties.propertiesConnection.nodes.length)"
      :rows="formattedPropertyNodes"
      :line-numbers="false"
      @on-row-click="onRowClick"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 25, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: this.$t('Pages.PushNotifications.Next'),
        prevLabel: this.$t('Pages.PushNotifications.Prev'),
        rowsPerPageLabel: this.$t('Pages.PushNotifications.RowsPerPage'),
        ofLabel: 'of',
        pageLabel: this.$t('Pages.PushNotifications.Page'), // for 'pages' mode
        allLabel: this.$t('Pages.PushNotifications.All'),
      }"
    >
      <div slot="emptystate" class="vgt-center-align vgt-text-disabled">
        {{ $t('Pages.Publishing.NoSearchData') }}
      </div>
      <template slot="table-row" slot-scope="record">
        <span class="uk-width-expand" v-if="record.column.field === 'icon'">
          <div uk-icon="icon: album; ratio: 1" :uk-tooltip="$t('Pages.Content.ContentTypes.Folder')"/>
        </span>
        <span v-if="record.column.field === 'keyart'"> 
          <ImageOrAbbrevation :src="record.row.property.icon? record.row.property.icon.url: ''" :alt="propertyName( record.row.property )" width="85px" imageClass='uk-border-rounded'/>
        </span>
        <div v-if="record.column.field === 'name'" class="name uk-width-large uk-text-truncate uk-text-left" >
          <span>{{  propertyName( record.row.property ) }}</span>
        </div>
        <span v-if="record.column.field === 'folders'" class="uk-text-center">{{ record.row.property.folders ? record.row.property.folders.totalCount : 0 }}</span> 
        <span v-if="record.column.field === 'videos'" class="uk-text-center">{{ record.row.property.vods ? record.row.property.vods.totalCount : 0 }}</span>
        <span v-if="record.column.field === 'liveStreams'" class="uk-text-center">{{ record.row.property.streams ? record.row.property.streams.totalCount : 0 }}</span>
        <span v-if="record.column.field === 'ar'" class="uk-text-center">{{ record.row.property.ar ? record.row.property.ar.totalCount : 0 }}</span>
        <!-- <span v-if="record.column.field === 'interactiveVideos'" class="uk-text-center">{{ record.row.property.interactives ? record.row.property.interactives.totalCount : 0 }}</span> -->
        <span v-if="record.column.field === 'ads'" class="uk-text-center">{{ record.row.property.ads ? record.row.property.ads.totalCount : 0 }}</span>
        <span class="uk-text-center" v-if="(isAdmin || mutationRoles.includes(propertyMap[record.row.property.id])) && record.column.field === 'actions'" >
           <div uk-icon="icon: pencil; ratio: 1" class="trash-icon" @click.stop="clickEdit( record.row.property )" />
        </span>
        <span class="uk-text-center" v-if="(!isAdmin && !mutationRoles.includes(propertyMap[record.row.property.id])) && record.column.field === 'actions'">
           <div uk-icon="icon: pencil; ratio: 1" class="trash-icon" @click.stop="" :uk-tooltip="$t('Generic.Errors.InsufficientPermission')" style="cursor: not-allowed"/>
        </span>
        <span class="uk-text-center uk-margin-left" v-if="(isAdmin || mutationRoles.includes(propertyMap[record.row.property.id])) && record.column.field === 'actions'">
          <span v-if="deletingList.includes( record.row.property ) && (isAdmin || mutationRoles.includes(propertyMap[record.row.property.id]))" uk-spinner="ratio: 1" />
          <div v-if="!deletingList.includes( record.row.property ) && (isAdmin || mutationRoles.includes(propertyMap[record.row.property.id]))" uk-icon="icon: trash; ratio: 1" class="trash-icon" @click.stop="deleteProperty(record.row.property)" />
        </span>
        <span class="uk-text-center uk-margin-left" v-if="(!isAdmin && !mutationRoles.includes(propertyMap[record.row.property.id])) && record.column.field === 'actions'">
          <div uk-icon="icon: trash; ratio: 1" class="trash-icon" @click.stop="" :uk-tooltip="$t('Generic.Errors.InsufficientPermission')" style="cursor: not-allowed" />
        </span>
      </template>
    </vue-good-table>
    <div id="info-publishing-modal" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.CannotDelete') }}</p>
      </div>
    </div>
    <div id="new-publishing-folder-modal" container="#publishing" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ editProperty ? $t('Pages.Publishing.EditFolder') :  $t('Pages.Publishing.AddFolder')}}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
           <div class="uk-flex uk-flex-column uk-flex-middle">
            <ImgOrFallback class="uk-margin-bottom" :src="form.keyArtMedia ? form.keyArtMedia.file.url : icon"  height="160px" width="283px" :imageClass="'uk-border-rounded'"/>
            <h5 class="uk-text-muted uk-margin-remove">{{$t('Generic.Labels.Image16x9')}}</h5>
            <button class="uk-margin-small-top uk-button uk-button-primary uk-float-right uk-width-auto" @click="openMediaPicker()">{{ $t('Pages.Publishing.UploadKeyArt') }}</button>
          </div>
          <form id="new-folder" class="uk-form-stacked uk-text-left" @submit.prevent="onSubmit();">
              <button class="uk-modal-close-default" type="button" uk-close @click="resetForm()"></button>
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Pages.Publishing.FolderName') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" type="text" 
                          :class="{ 'invalid-border': form.nameError }"
                          v-model.trim="form.name"
                          autocomplete="off"
                          required
                          @input="validateName($event.target.value)"
                          :placeholder="$t('Pages.Publishing.Placeholders.FolderName')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="form.nameError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="form.nameError" >{{form.nameError || 'Name should be atleast 2 characters'}}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{ $t('Pages.Publishing.Headline') }}</label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" 
                          :class="{ 'invalid-border': form.headlineError }"
                          type="text"
                          v-model.trim="form.headline"
                          autocomplete="off"
                          @input="validateHeadline($event.target.value)"
                          :placeholder="$t('Pages.Publishing.Placeholders.Headline')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="form.headlineError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="form.headlineError" >{{form.headlineError}}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin uk-margin-remove-bottom">
                  <label class="uk-form-label">{{ $t('Pages.Publishing.Description') }}</label>
                  <div class="uk-form-controls">
                      <textarea class="uk-textarea" 
                      rows="5"
                      type="text"
                      v-model.trim="form.description"
                      autocomplete="off"
                      :placeholder="$t('Pages.Publishing.Placeholders.Description')" />
                  </div>
              </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click="onSubmit()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    <div v-if="showPicker" id="media-picker-publishing-modal" container="#publishing" uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
      <ModalMediaPicker  media-class-filter="IMAGE" @cancel="closeModal()" @select="selectedKeyart( $event )" />
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PublishingDashboard from './PublishingDashboard';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import Notification from '@/components/util/Notification';
import ImgOrFallback from '@/components/images/ImageOrFallback';
import { Permissions } from '@/utils/enums';

export default {
  name: 'PublishingView',
  components: {
    PublishingDashboard, VueGoodTable, ImageOrAbbrevation, ImgOrFallback, ModalMediaPicker: () => import('@/components/modals/ModalMediaPicker.vue')
  },
  data() {
    return {
      deletingList: [],
      mutationRoles: [Permissions.ADMIN],
      fetching: false,
      form: {
        name: '',
        headline: '',
        description: '',
        nameError: '',
        headlineError: '',
        keyArtMedia : ''
      },
      error: '',
      isLoading: false,
      showPicker: false,
      editProperty: null,
      icon: '',
      searchKey:  this.$route?.query?.search || '',
      orgId: this.$route.params.id,
      loadData: true
    }
  },
  computed: {
    ...mapState({
      properties: state => state.venom.org?.properties || {},
      propertiesLoading: state => state.venom.org?.propertiesLoading || false,
      hasNextPage: state => ( state.venom.org?.propertiesConnection?.pageInfo?.hasNextPage ),
      viewer: state => state.venom.org.viewer
    }),
    propertyNodes() {
      return this.properties ? this.properties?.propertiesConnection?.nodes?.filter(app => {
        return (app.i18nName?.localized?.value.toLowerCase().includes(this.searchKey.toLowerCase()) );
      }) : [];
    },
    tableHeaders () {
      return [{
        label: this.$t('Pages.Publishing.Headers.Type'),
        field: 'icon',
        sortable: false,
        tdClass: 'uk-text-middle'
      },
      {
        label: this.$t('Pages.Content.TileArt'),
        field: 'keyart',
        width: '11%',
        tdClass: 'uk-width-medium uk-preserve-width ',
        thClass: 'uk-width-small uk-preserve-width uk-text-middle',
        sortable: false
      },
      {
        label: this.$t('Pages.Publishing.Headers.Name'),
        field: 'name',
        thClass: 'uk-width-2xlarge uk-preserve-width ',
        tdClass: 'uk-width-2xlarge uk-preserve-width uk-text-middle',
      },
      {
        label: this.$t('Pages.Publishing.Headers.Folders'),
        field: 'folders',
        thClass: 'uk-width-small uk-text-center',
        tdClass: 'uk-width-small uk-text-center uk-text-middle',
        sortable: false
      },
      {
        label: this.$t('Pages.Publishing.Headers.Videos'),
        field: 'videos',
        thClass: 'uk-width-medium uk-text-center',
        tdClass: 'uk-width-medium uk-text-center uk-text-middle',
        sortable: false
      },
      {
        label: this.$t('Pages.Publishing.Headers.LiveStreams'),
        field: 'liveStreams',
        thClass: 'uk-width-large uk-text-center uk-text-truncate',
        tdClass: 'uk-width-large uk-text-center uk-text-middle',
        sortable: false
      },
      // {
      //   label: this.$t('Pages.Publishing.Headers.InteractiveVideos'),
      //   field: 'interactiveVideos',
      //   thClass: 'uk-width-medium@xl uk-text-center uk-width-large',
      //   tdClass: 'uk-width-medium@xl uk-text-center uk-text-middle uk-width-large',
      //   sortable: false
      // },
       {
        label: this.$t('Pages.Publishing.Headers.AR'),
        field: 'ar',
        thClass: 'uk-width-medium uk-text-center',
        tdClass: 'uk-width-medium uk-text-center uk-text-middle',
        sortable: false
      },
      {
        label: this.$t('Pages.Publishing.Headers.Ads'),
        field: 'ads',
        thClass: 'uk-width-medium uk-text-center',
        tdClass: 'uk-width-medium uk-text-center uk-text-middle',
        sortable: false
      },
     
      {
        label: this.$t('Actions.Actions'),
        field: 'actions',
        thClass: 'uk-width-medium uk-text-center',
        tdClass: 'uk-width-medium uk-text-center uk-text-middle',
        sortable: false
      }]
    },
    formattedPropertyNodes () {
      return this.propertyNodes.map(node => {
        return {
          property: node,
          type: 'album',
          keyart: node?.icon?.url || '',
          name: this.propertyName(node),
          // folders: node?.folders?.totalCount || 0,
          // videos: node?.vods?.totalCount || 0,
          // liveStreams: node?.streams?.totalCount || 0,
          // interactiveVideos: node?.interactives?.totalCount || 0,
          // ads: node?.ads?.totalCount || 0,
          // ar: node?.ar?.totalCount || 0,
          actions: {} 
        }
      })
    },
    pageInfo() {
      return this.properties.propertiesConnection?.pageInfo || {};
    },
    propertyMap () {
      const prop = {} 
      this.viewer?.permissions?.forEach(perm => {
        prop[perm.property.id] = this.isAdmin ? Permissions.ADMIN : perm.type
      })
      return prop
    },
    isAdmin () {
      return this.viewer?.role === Permissions.ADMIN ? true : false
    },
    isAnyAdmin () { //Admin to any folder
      if (this.isAdmin) return true
      else {
        let isAdmin = false
        if (!this.viewer?.permissions?.length) return isAdmin
        for (const perm of this.viewer?.permissions) {
          if (perm.type === Permissions.ADMIN) {
            isAdmin = true
            break
          }
        }
        return isAdmin
      }
    }
  },
  methods: {
    onRowClick ({ row }) {
      const { property } = row
      this.$router.push({ path: `/organizations/${this.orgId}/publishing/${property.id}/${this.propertyName(property)}/content`})
    },
    validateFolderForm () {
      let isValid = true
      this.validateName(this.form.name)
      this.validateHeadline(this.form.headline)
      if (this.form.nameError || this.form.headlineError) {
        isValid = false
      } else if (this.editProperty && !this.form.name) {
        this.nameError = this.$t('Generic.Errors.Min2Chars')
      } else if (!this.editProperty) {
        if (this.form.nameError || this.form.headlineError) {
          isValid = false
        } else if (!this.form.name || (this.form.headline && this.form.headline.length < 2)) {
          this.form.nameError = !this.form.name ? this.$t('Generic.Errors.Min2Chars') : false
          this.form.headlineError = (this.form.headline && this.form.headline.length < 2) ? this.$t('Generic.Errors.Min3Chars') : false
          isValid = false
        }
      }
      return isValid
    },
    async fetchOrgProperties() {
      // if(Object.entries(this.properties).length === 0)
        this.fetching = true;
      const response  = await this.$store.dispatch('fetchProperties', { orgId: this.orgId, sort: "name", after: null });
      this.fetchMore(response);
      this.fetching = false;
    },
    async fetchMore (response) {
      if(response?.organization?.propertiesConnection?.pageInfo?.hasNextPage && this.loadData) {
        const res = await this.$store.dispatch('fetchProperties', { orgId: this.orgId, sort: "name", after: response?.organization?.propertiesConnection?.pageInfo?.endCursor || null });
        this.fetchMore(res);
      }
    },
    propertyName( property ) {
      return property.i18nName?.localized?.value;
    },
    deletePropRef ( property ) {
      this.deletingList.forEach( i=> {
        if( i.id == property.id )
          this.deletingList.splice(i,1);
      });
    },
    async deleteProperty( property ) {
      if ( property.folders?.totalCount > 0 ) {
        window.UIkit.modal('#info-publishing-modal').show();
      } else {
        const UIkit = window.UIkit;
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDelete', { name: this.propertyName( property ) }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingList.push( property );
          const response = await this.$store.dispatch( 'deleteProperty', { orgId: this.orgId, propertyId: property.id } );
          this.fetchOrgProperties();
          if ( response ) {
            this.deletePropRef( property );
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.propertyName( property ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        },()=>{});
      }
    },
    async onSubmit() {
      try {
        if (!this.validateFolderForm()) return false
        this.isLoading = true;
        const property = this.editProperty ? await this.$store.dispatch("updateProperty", {
          i18nName: [ { language: 'en-US', value: this.form.name } ],
          i18nHeadline: [ { language: 'en-US', value: this.form.headline } ],
          i18nDescription: [ { language: 'en-US', value: this.form.description } ],
          keyArt: this.form.keyArtMedia ? { id: this.form.keyArtMedia.id } : null,
          organization: this.orgId,
          property: this.editProperty.id,
          isProperty: true
        }) : await this.$store.dispatch("createProperty", {
          name: this.form.name,
          headline: this.form.headline,
          description: this.form.description,
          keyArt: this.form.keyArtMedia ? { id: this.form.keyArtMedia.id } : null,
          organization: this.orgId
        });
        this.fetchOrgProperties();
        await this.$store.dispatch('getOrgPermissions', this.orgId)
        window.UIkit.modal('#new-publishing-folder-modal').hide();
        if( property && !this.editProperty )
          Notification.showNotification(this.$t('Actions.Created'), this.$t('Pages.MediaLibrary.FolderCreated', { name: this.form.name }));
        else
          Notification.showNotification(this.$t('Actions.Updated'), this.$t('Pages.MediaLibrary.FolderUpdated'));
        this.isLoading = false;
        this.resetForm();
      } catch (err) {
        this.resetForm();
        this.error = err.message.replace("Error:", "");
      }
    },
    validateName( name ) {
      if ( name.match(/[%,#,/]/) ) {
        this.form.nameError = this.$t('Generic.Errors.HashNotSupported');
      } else if (this.form.nameError) {
        this.form.nameError = this.form.name.length >= 2 ? false : this.$t('Generic.Errors.Min2Chars')
      } else {
        this.form.nameError = this.form.name && this.form.name.length<2 || !name.trim() ? this.$t('Generic.Errors.Min2Chars'): false;
      }
    },
    validateHeadline( headline ) {
      if (this.form.headlineError) {
        this.form.headlineError = headline.length === 0 || headline.length > 2 ? false : this.$t('Generic.Errors.Min3Chars')
      } else {
        this.form.headlineError = headline.length>0 && headline.length<=2 ? this.$t('Generic.Errors.Min3Chars') : false;
      }
    },
    closeModal () {
      window.UIkit.modal('#media-picker-publishing-modal').hide();
      window.UIkit.modal('#new-publishing-folder-modal').show();
      setTimeout( function() {
        this.showPicker = false;
      }, 100 );
    },
    selectedKeyart ( media ) {
      this.form.keyArtMedia = media;
      this.closeModal();
    },
    openMediaPicker () {
      this.showPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-publishing-modal').show();
      }, 100 );
    },
    resetForm () {
      this.form.name = '';
      this.form.description = '';
      this.form.headline = '';
      this.form.keyArtMedia = '';
      this.form.nameError = '';
      this.form.headlineError = '';
      this.icon = '';
      this.editProperty = null;
    },
    clickEdit ( property ) {
      this.editProperty = property;
      window.UIkit.modal('#new-publishing-folder-modal').show();
      this.form.name = this.propertyName(property);
      this.form.headline = property.i18nHeadline?.localized?.value;
      this.form.description = property.i18nDescription?.localized?.value;
      this.icon = property.icon ? property.icon.url : '';
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  beforeDestroy () {
    this.loadData = false
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.fetchOrgProperties();
  }
}
</script>

<style lang="scss">
.trash-icon {
  width: 20px;
  height: 20px;
}

.table-row {
  cursor: pointer;
}
.new-folder-btn {
  padding: 0 20px;
}

table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}
table.vgt-table tr.clickable:hover {
  background-color: var(--app-highlight-color-gradient);
}
.vgt-table th.sortable button {
  width: auto;
  position: relative;
  margin-left: 5px;
  top: -.25rem;
}
</style>
